/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
ion-title {
    text-align: center;
}

.change-address-shipping-modal {
    //--height: 60%;
    align-items: flex-end;
    border-radius: 20px;
}

.modal-wrapper.sc-ion-modal-ios {
    border-radius: 20px 20px 0 0;
}

.btn {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

ion-button,
p,
ion-card-content,
h6,
h5,
h4,
h3,
h2,
h1,
div {
    outline: 0;
}

.reset {
    text-align: center;
}

.content {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

ion-back-button.arabe {
    //transform: rotate(180deg);
}

/* @font-face {
    font-family: "e";
    src: url("assets/g.ttf");
}

* {
    font-family: "e" !important;
    font-weight: normal;
    font-style: normal;
} */


/*
.bgg {
  --background: url('https://cdn.um-up.com/app-photos/bg.png') no-repeat center center / cover;
}*/